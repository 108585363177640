exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blogPost.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-blog-post-js-content-file-path-src-pages-projects-3-d-scanning-for-architects-post-mdx": () => import("./../../../src/pages/blogPost.js?__contentFilePath=/home/runner/work/portfolio/portfolio/src/pages/projects/3d-scanning-for-architects/post.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-src-pages-projects-3-d-scanning-for-architects-post-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-src-pages-projects-generative-prints-post-mdx": () => import("./../../../src/pages/blogPost.js?__contentFilePath=/home/runner/work/portfolio/portfolio/src/pages/projects/generative-prints/post.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-src-pages-projects-generative-prints-post-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-src-pages-projects-pixel-painter-post-mdx": () => import("./../../../src/pages/blogPost.js?__contentFilePath=/home/runner/work/portfolio/portfolio/src/pages/projects/pixel-painter/post.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-src-pages-projects-pixel-painter-post-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-src-pages-projects-quicksettings-iterator-post-mdx": () => import("./../../../src/pages/blogPost.js?__contentFilePath=/home/runner/work/portfolio/portfolio/src/pages/projects/quicksettings-iterator/post.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-src-pages-projects-quicksettings-iterator-post-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-src-pages-projects-sketchbook-post-mdx": () => import("./../../../src/pages/blogPost.js?__contentFilePath=/home/runner/work/portfolio/portfolio/src/pages/projects/sketchbook/post.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-src-pages-projects-sketchbook-post-mdx" */),
  "component---src-pages-blog-post-js-content-file-path-src-pages-projects-thesis-post-mdx": () => import("./../../../src/pages/blogPost.js?__contentFilePath=/home/runner/work/portfolio/portfolio/src/pages/projects/thesis/post.mdx" /* webpackChunkName: "component---src-pages-blog-post-js-content-file-path-src-pages-projects-thesis-post-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-3-d-scanning-for-architects-post-mdx": () => import("./../../../src/pages/projects/3d-scanning-for-architects/post.mdx" /* webpackChunkName: "component---src-pages-projects-3-d-scanning-for-architects-post-mdx" */),
  "component---src-pages-projects-generative-prints-post-mdx": () => import("./../../../src/pages/projects/generative-prints/post.mdx" /* webpackChunkName: "component---src-pages-projects-generative-prints-post-mdx" */),
  "component---src-pages-projects-pixel-painter-post-mdx": () => import("./../../../src/pages/projects/pixel-painter/post.mdx" /* webpackChunkName: "component---src-pages-projects-pixel-painter-post-mdx" */),
  "component---src-pages-projects-quicksettings-iterator-post-mdx": () => import("./../../../src/pages/projects/quicksettings-iterator/post.mdx" /* webpackChunkName: "component---src-pages-projects-quicksettings-iterator-post-mdx" */),
  "component---src-pages-projects-sketchbook-post-mdx": () => import("./../../../src/pages/projects/sketchbook/post.mdx" /* webpackChunkName: "component---src-pages-projects-sketchbook-post-mdx" */),
  "component---src-pages-projects-thesis-post-mdx": () => import("./../../../src/pages/projects/thesis/post.mdx" /* webpackChunkName: "component---src-pages-projects-thesis-post-mdx" */)
}

